<div class="card">
  <div class="card-body d-flex flex-column align-items-center">
    <div class="title text-center">
      <p>
        Pwani University Tender portal
      </p>
      <h4>
       Read <a href="assets/images/CONTINUOUS REGISTRATION OF SUPPLIERS  2022-2024  FY.pdf" target="_blank" style="font-weight: bold; color: #020c17;">these instructions</a> before proceeding with Registration
      </h4>
    </div>
    <div class="link col-12 text-center mt-auto">
      <a routerLink="/sign-up" class="mb-0" id="link">Don't have an Account? Sign Up</a>&nbsp;OR&nbsp;
      <a routerLink="/sign-in" class="mb-0 tex" id="link">Already have an account? Sign In</a>
    </div>
  </div>
</div>

