<html>
<!--breadcrumb-->
<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3 p-4" style="background-color:#04a7f3;">
	<div class="breadcrumb-title pe-3n text-dark">
    Submitted Expressions of Interest(EOI)</div>
	<div class="ps-3">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb mb-0 p-0">
				<li class="breadcrumb-item"><a href="#" (click)="$event.preventDefault()"><i class="bx bx-home-alt"></i></a>
				</li>
				<li class="breadcrumb-item active text-dark" aria-current="page">(EOI)</li>
			</ol>
		</nav>
	</div>
</div>
<!--end breadcrumb-->
<!-- Page-body start -->
 <div class="body">
  <div class="col-sm-12">
    <div class="table-responsive">
      <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="table-light">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Issue Date</th>
            <th scope="col">Closing Date</th>
            <th scope="col">Type</th>
            <th scope="col">Category</th>
            <th scope="col">Category Name</th>
            <th scope="col">Description</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let submittedEOI of submittedEoiList">
            <td>{{submittedEOI.no}}</td>
            <td>{{submittedEOI.issueDate}}</td>
            <td>{{submittedEOI.closingDate}}</td>
            <td>{{submittedEOI.type}}</td>
            <td>{{submittedEOI.supplierCategory}}</td>
            <td>{{submittedEOI.categoryName}}</td>
            <td>{{submittedEOI.decription}}</td>
            <td>{{submittedEOI.status}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</html>

