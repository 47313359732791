import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/shared/config';
import { Observable } from 'rxjs';
import { SubmittedRfqp } from './submitted-rfqp.model';

const api_url = config.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SubmittedRequestForProposalService {

  constructor(private httpClient: HttpClient) { }


  getRfpsByCompanyNo(companyNo): Observable<SubmittedRfqp>{
    return this.httpClient.get<SubmittedRfqp>(api_url+ '/RFPs/GetSubmittedRFPs/'+ companyNo);

  }
}
