  <!--breadcrumb-->
<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3 p-4" style="background-color:#04a7f3;">
	<div class="breadcrumb-title pe-3n text-dark">Restricted Tenders</div>
	<div class="ps-3">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb mb-0 p-0">
				<li class="breadcrumb-item"><a href="#" (click)="$event.preventDefault()"><i class="bx bx-home-alt"></i></a>
				</li>
				<li class="breadcrumb-item active text-dark" aria-current="page">Active Restricted Tenders</li>
			</ol>
		</nav>
	</div>
</div>
<!--end breadcrumb-->
<!-- Page-body start -->
  <div class="card">
    <div class="card-body">
    <div class="table-responsive border rounded">
      <table class="table table-hover mb-0 " datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="table-light">
          <tr>
            <th scope="col">Tender No</th>
            <th>Name</th>
            <!-- <th scope="col">Proc Method</th> -->
            <th scope="col">Submisssion Method</th>
            <th scope="col">Tender Fee</th>
            <!-- <th scope="col">Publish Date</th> -->
            <th scope="col">Closing date</th>
            <!-- <th scope="col">Opening venue</th> -->
            <th scope="col">PE</th>
            <th scope="col">Addendum</th>
            <th scope="col">View</th>
            <th >Attachment</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tender of restrictedTenderLists">
            <td>{{tender.no}}</td>
            <td>{{tender.description}}</td>
            <!-- <td>{{tender.procurementMethod}}</td> -->
            <td>{{tender.category}}</td>
            <td>{{tender.tenderFee}}</td>
            <!-- <td>{{tender.publishedDate}}</td> -->
            <td>{{tender.closeDate}}</td>
            <!-- <td>{{tender.openingVenue}}</td> -->
            <td>{{tender.procumentExecutive}}</td>
            <td>{{tender.addendum}}</td>
            <td>
              <button class="btn btn-sm btn-danger" (click)="triggerModal(modalData,tender.no )">View</button>
            </td>
            <td>
              <a class="btn btn-sm btn-success" [href]="tender?.documementLink" target="_blank">Attachment</a>
            </td>
            <td>
              <button type="button" routerLink="restrictedTender/{{tender.no}}" (click)="$event.preventDefault()" class="ms-3 btn btn-info btn-sm">Apply</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #modalData let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title">Open Tender in details</h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row g-0">
      <div class="card-header">
        <h5 class="mb-0">Tender details</h5>
      </div>
      <div class="col-md-12 border-end">
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Tender No:</h6>
              <span class="text-secondary font-weight-light">{{restrictedTenderRowList?.no}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Proc Method:</h6>
              <span class="text-secondary font-weight-light">{{restrictedTenderRowList?.procurementMethod}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Category:</h6>
              <span class="text-secondary font-weight-light">{{restrictedTenderRowList?.category}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Tender Fee:</h6>
              <span class="text-secondary font-weight-light">{{restrictedTenderRowList?.tenderFee}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">publis Date:</h6>
              <span class="text-secondary">{{restrictedTenderRowList?.publishedDate}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Closed Date:</h6>
              <span class="text-secondary">{{restrictedTenderRowList?.closeDate}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Proc executive:</h6>
              <span class="text-secondary">{{restrictedTenderRowList?.procumentExecutive}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Addedum:</h6>
              <span class="text-secondary">{{restrictedTenderRowList?.addendum}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mb-0">Tender Venue:</h6>
              <span class="text-secondary font-weight-light">{{restrictedTenderRowList?.openingVenue}}</span>
            </li>
          </ul>
        </div>
      </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

