import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-awarded-restricted-tender',
  templateUrl: './open-awarded-restricted-tender.component.html',
  styleUrls: ['./open-awarded-restricted-tender.component.scss']
})
export class OpenAwardedRestrictedTenderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
