import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Standard } from '@app/new-application/standard.model';
import { config } from '@app/shared/config';
import { Observable } from 'rxjs';
import { Files } from './files';
import { RestrictedTenders } from './restricted-tenders.model';
import { UploadedDocument } from './uploaded-document.model';

const api_url = config.apiUrl;


@Injectable({
  providedIn: 'root'
})
export class RestrictedTendersService {

  constructor(private httpClient: HttpClient) { }

  createNewTender(restrictedTenderBody): Observable<RestrictedTenders>{

    return this.httpClient.post<RestrictedTenders>(api_url+'/TenderApplication', restrictedTenderBody);

  }

  getOpenRestictedTender(companyNo): Observable<RestrictedTenders>{
    return this.httpClient.get<RestrictedTenders>(api_url +'/Tenders/GetRestrictedTenders/' + companyNo)

  }
  getRestrictedTenderByTenderNo(tenderNo): Observable<RestrictedTenders>{
    return this.httpClient.get<RestrictedTenders>(api_url +'/Tenders/GetRestrictedTenderById/'+tenderNo)
  }
  getOpenRestrictedTenderDocument(): Observable<Standard>{
    return this.httpClient.get<Standard>(api_url+'/TenderApplication/GetDocumentTypes');
  }

  uploadOpenRestrictedTenderDocument(fileUploadInfo): Observable<Files>{
    return this.httpClient.post<Files>(api_url+'/TenderApplication/UploadTenderDocument', fileUploadInfo);
  }
  getUploadedTenderDocument(applicationNo): Observable<UploadedDocument>{
    return this.httpClient.get<UploadedDocument>(api_url +'/FileManager/GetUploadedDocuments/'+applicationNo)
  }
}
