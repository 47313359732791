<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3 p-4" style="background-color:#04a7f3;">
	<div class="breadcrumb-title pe-3n text-dark">Submitted Request for Proposal</div>
	<div class="ps-3">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb mb-0 p-0">
				<li class="breadcrumb-item"><a href="#" (click)="$event.preventDefault()"><i class="bx bx-home-alt"></i></a>
				</li>
				<li class="breadcrumb-item active text-dark" aria-current="page">Submitted Request for Proposal</li>
			</ol>
		</nav>
	</div>
</div>
<div class="col-sm-12">
  <div class="p-4 table-responsive">
    <table class="table mb-0" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead class="table-light">
        <tr>
          <!-- <th scope="col">Applicat No</th> -->
          <th scope="col">No</th>
          <th scope="col">Issue Date</th>
          <th scope="col">Closing Date</th>
          <th scope="col">Type</th>
          <th scope="col">Category</th>
          <th scope="col">Category Name</th>
          <th scope="col">Description</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let submittedRfps of submittedRfqpsList">
          <td>{{submittedRfps.no}}</td>
          <td>{{submittedRfps.issueDate}}</td>
          <td>{{submittedRfps.closingDate}}</td>
          <td>{{submittedRfps.type}}</td>
          <td>{{submittedRfps.supplierCategory}}</td>
          <td>{{submittedRfps.categoryName}}</td>
          <td>{{submittedRfps.decription}}</td>
          <td>{{submittedRfps.status}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

