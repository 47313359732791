import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { Subject } from 'rxjs';
import { SubmittedRequestForProposalService } from './submitted-request-for-proposal.service';
import { SubmittedRfqp } from './submitted-rfqp.model';

@Component({
  selector: 'app-submitted-request-for-proposal',
  templateUrl: './submitted-request-for-proposal.component.html',
  styleUrls: ['./submitted-request-for-proposal.component.scss']
})
export class SubmittedRequestForProposalComponent implements OnInit {


  submittedRfqpsList: SubmittedRfqp;
  companyNo: string;
  
  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10
   };
   dtTrigger: Subject<any> = new Subject<any>();


  constructor(public authService: AuthService, private rfpsServices: SubmittedRequestForProposalService) {
    this.authService.getCompanyNo$().subscribe(string => this.companyNo = string);
   }

  ngOnInit(): void {
    this.getAllRfpsByCompanyNo()
  } 

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  getAllRfpsByCompanyNo(){
    this.rfpsServices.getRfpsByCompanyNo(this.companyNo).subscribe(data=>{
      this.submittedRfqpsList = data;
      this.dtTrigger.next();
    })
  }

}
