import { Managers } from './managers.model';
import { Directors } from './directors.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/shared/config';
import { Observable } from 'rxjs';
import { GeneralInformation } from './general-information.model';
import { Branches } from './branches.model';
import { Bank } from './bank.model';
import { Client } from './client.model';
import { Litigation } from './litigation.model';
import { UploadedDocument } from './uploaded-document.model';
import { Documents } from './documents.model';
import { Standard } from './standard.model';
import { ShareHolder } from './share-holder.model';
import { Category } from './category.model';


const api_url = config.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class NewApplicationService {

  constructor(private httpClient: HttpClient) { }

  //Bussiness General Information
  createGeneralformation(generalInformationBody): Observable<GeneralInformation>{

    return this.httpClient.post<GeneralInformation>(api_url+'/Profile', generalInformationBody);

  }
  getGeneralInformation(applicationNo): Observable<GeneralInformation>{
    return this.httpClient.get<GeneralInformation>(api_url+'/Profile/GetProfileById/'+applicationNo);

  }
  // Bussiness Directors
  createDirector(directorDetailsBody): Observable<Directors>{
    return this.httpClient.post<Directors>(api_url+'/SupplierDirector', directorDetailsBody);

  }

  getAllDirectors(applicationNo): Observable<Directors>{
    return this.httpClient.get<Directors>(api_url+'/SupplierDirector/'+applicationNo);
  }

  updateDirector(lineNo,applicationNo): Observable<Directors>{
    return this.httpClient.get<Directors>(api_url+'/SupplierDirector/'+ lineNo +'/'+applicationNo);
  }
  deleteDirector(lineNo,applicationNo): Observable<Directors>{
    return this.httpClient.get<Directors>(api_url+'/SupplierDirector/Delete/'+ lineNo +'/'+applicationNo);
  }
  getNationality(): Observable<Standard>{
    return this.httpClient.get<Standard>(api_url+'/HelperFunctions/GetNationalities');
  }
  // Category
  createCategories(categoryBody): Observable<Category>{
    return this.httpClient.post<Category>(api_url+'/SupplierCategory', categoryBody);
  }
  getSupplierCategory(): Observable<Standard>{
    return this.httpClient.get<Standard>(api_url+'/HelperFunctions/GetSupplierCategories');
  }
  getSupplierBusinessType(): Observable<any>{
    return this.httpClient.get<any>(api_url+'/HelperFunctions/GetBusinessTypes');
  }
  getSuppliersByApplicationNo(applicationNo): Observable<Category>{
    return this.httpClient.get<Category>(api_url+'/SupplierCategory/'+ applicationNo);
  }
  updateCategory(lineNo,applicationNo): Observable<Category>{
    return this.httpClient.get<Category>(api_url+'/SupplierCategory/'+ lineNo +'/'+applicationNo);
  }
  deleteCategory(lineNo,applicationNo): Observable<Category>{
    return this.httpClient.get<Category>(api_url+'/SupplierCategory/Delete/'+ lineNo +'/'+applicationNo);
  }
          // Bussiness client details
    createClientDetails(clientDetailsBody): Observable<Client>{
      return this.httpClient.post<Client>(api_url+'/Client', clientDetailsBody);
    }
    getAllClients(applicationNo): Observable<Client>{
     return this.httpClient.get<Client>(api_url+'/Client/'+applicationNo);
    }

    updateClient(lineNo,applicationNo): Observable<Client>{
      return this.httpClient.get<Client>(api_url+'/Client/'+ lineNo +'/'+applicationNo);
    }
    deleteClient(lineNo,applicationNo): Observable<Client>{
     return this.httpClient.get<Client>(api_url+'/Client/Delete/'+ lineNo +'/'+applicationNo);
    }
  //Document  Apis
  uploadDocument(fileUploadInfo): Observable<Documents>{

    return this.httpClient.post<Documents>(api_url+ '/FileManager', fileUploadInfo);

  }
  uploadSupplierDocument(suplierFileUploadInfo): Observable<Documents>{

    return this.httpClient.post<Documents>(api_url+ '/FileManager', suplierFileUploadInfo);

  }
  getDocumentTypes(): Observable<Standard>{

    return this.httpClient.get<Standard>(api_url+'/FileManager/GetDocumentTypes');

  }
  getUploadedDocuments(companyNo,documentNo): Observable<UploadedDocument>{

    return this.httpClient.get<UploadedDocument>(api_url+'/FileManager/GetUploadedDocuments/'+companyNo +'/'+documentNo);

  }
  deleteDocument(lineNo,documentNo): Observable<UploadedDocument>{

    return this.httpClient.get<UploadedDocument>(api_url+'/FileManager/Delete/'+lineNo+'/'+documentNo);

  }

      //Litigation details
      uploadLitigationDocument(litigationFile): Observable<Documents>{

        return this.httpClient.post<Documents>(api_url+ '/FileManager', litigationFile);

      }

      createLitigationHistory(litigationBody): Observable<Litigation>{
          return this.httpClient.post<Litigation>(api_url+'/SupplierLitigationHistory', litigationBody);
        }
      getAllLitigationHistory(applicationNo): Observable<Litigation>{
        return this.httpClient.get<Litigation>(api_url+'/SupplierLitigationHistory/'+applicationNo);
      }
      updateLiticationHistory(lineNo,applicationNo): Observable<Litigation>{
      return this.httpClient.get<Litigation>(api_url+'/SupplierLitigationHistory/'+ lineNo +'/'+applicationNo);
        }
      deleteLitigationHistory(lineNo,applicationNo): Observable<Litigation>{
      return this.httpClient.get<Litigation>(api_url+'/SupplierLitigationHistory/Delete/'+ lineNo +'/'+applicationNo);
      }

      getLitigationDocumentTypes(): Observable<Standard>{

        return this.httpClient.get<Standard>(api_url+'/SupplierLitigationHistory/GetLitigationDocumentTypes');

      }
      submitApplication(applicationBoy): Observable<Litigation>{
        return this.httpClient.post<Litigation>(api_url+'/SubmitApplication', applicationBoy);
      }
}
