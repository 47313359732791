import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { AppGuard } from './auth/guards/app.guard';

const routes: Routes = [
  { path: '', redirectTo: '/PeRegistration', pathMatch: 'full'  },
  {
    path: '',
    canActivate: [AppGuard],
    component: FullLayoutComponent,
    children: [

        { path: 'user-profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule) },
        //Dashboard
        { path: 'dashboard', loadChildren:()=>import('./dashboard/dashboard.module').then(m=>m.DashboardModule)},
        //Profile
        { path: 'my-profile', loadChildren:()=>import('./my-profile/my-profile.module').then(m=>m.MyProfileModule)},
        //New Application
        { path: 'newApplication', loadChildren:()=>import('./new-application/new-application.module').then(m=>m.NewApplicationModule)},
        { path: 'approvedApplication', loadChildren:()=>import('./approved-application/approved-application.module').then(m=>m.ApprovedApplicationModule)},
        { path: 'rejectedApplication', loadChildren:()=>import('./rejected-application/rejected-application.module').then(m=>m.RejectedApplicationModule)},
        { path: 'prequalification', loadChildren:()=>import('./business-profile/business-profile.module').then(m=>m.BusinessProfileModule)},
        //
        { path: 'closedTender', loadChildren:()=>import('./closed-tenders/closed-tender.module').then(m=>m.ClosedTenderModule)},
        //Open Tender
        {path: 'openTender',loadChildren:()=>import('./open-tenders/open-tenders.module').then(m=>m.OpenTendersModule)},
        {path: 'openSubmittedTender',loadChildren: () => import('./open-submitted-tender/open-submitted-tender.module').then(m => m.OpenSubmittedTenderModule)},
        {path: 'openAwardedTender',loadChildren: () => import('./open-awarded-tender/open-awarded-tender.module').then(m => m.OpenAwardedTenderModule)},
        //Restricted Tenders
        {path: 'restrictedTender',loadChildren: () => import('./restricted-tenders/restricted-tenders.module').then(m =>m.RestrictedTendersModule)},
        {path: 'openSubmittedRestrictedTender',loadChildren: () => import('./open-submitted-restricted-tender/open-submitted-restricted-tender.module').then(m =>m.OpenSubmittedRestrictedTenderModule)},
        {path: 'openSubmittedAwardedTender',loadChildren: () => import('./open-awarded-restricted-tender/open-awarded-restricted-tender.module').then(m =>m.OpenAwardedRestrictedTenderModule)},
        //Expression of intrest
        {path: 'activeExpressionOfIntrest',loadChildren: () => import('./active-expression-of-intrest/active-expression-of-intrest.module').then(m =>m.ActiveExpressionOfIntrestModule)},
        {path: 'submittedExpressionOfIntrest',loadChildren: () => import('./submitted-expression-of-intrest/submitted-expression-of-intrest.module').then(m =>m.SubmittedExpressionOfIntrestModule)},
        {path: 'awardedExpressionOfIntrest',loadChildren: () => import('./awarded-expression-of-intrest/awarded-expression-of-intrest.module').then(m =>m.AwardedExpressionOfIntrestModule)},
        // Request for proposal
        {path: 'activeReqestForProposal',loadChildren: () => import('./active-request-for-proposal/active-request-for-proposal.module').then(m =>m.ActiveRequestForProposalModule)},
        {path: 'submittedReqestForProposal',loadChildren: () => import('./submitted-request-for-proposal/submitted-request-for-proposal.module').then(m =>m.SubmittedRequestForProposalModule)},
        {path: 'awardedrequestForProposal',loadChildren: () => import('./awarded-request-for-proposal/awarded-request-for-proposal.module').then(m =>m.AwardedRequestForProposalModule)},
        //Request For quotation
        {path: 'activeRequestForQuotation',loadChildren: () => import('./active-request-for-quatation/active-request-for-quatation.module').then(m =>m.ActiveRequestForQuatationModule)},
        {path: 'submittedRequestForQuotation',loadChildren: () => import('./submitted-request-for-quatation/submitted-request-for-quatation.module').then(m =>m.SubmittedRequestForQuatationModule)},
        {path: 'awardedRequestForQuotation',loadChildren: () => import('./awarded-request-for-quatation/awarded-request-for-quatation.module').then(m =>m.AwardedRequestForQuatationModule)},
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
