import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-awarded-request-for-proposal',
  templateUrl: './awarded-request-for-proposal.component.html',
  styleUrls: ['./awarded-request-for-proposal.component.scss']
})
export class AwardedRequestForProposalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
