	<!--wrapper-->
	<div class="wrapper">
    <div class="section-authentication-signin">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
          <div class="col col-6">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body">
                <div class="card-title text-center mt-1 mb-1" >
                  <div>
                    <img src="assets/images/pwani.jpg" class="logo-icon" alt="PU Logo">
                  </div>
                  <!-- <h5 class="mb-5 mt-2" style="color: #607C8A;">User Registration</h5> -->
                </div>
                <hr>
                <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" [class.visible]="isVisible">
                  <strong>Error!</strong>{{error|json}}
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                </div>
                <form class="row g-3" [formGroup]="signupForm" autocomplete="off">
                  <div class="col-6">
                    <label for="companyNo" class="form-label">Company Registration No/Pin No:</label>
                    <input type="text" id="companyNo" class="form-control" formControlName="companyNo"[ngClass]="{ 'is-invalid': f.submitted && f.businessCategory.errors }">
                    <div *ngIf="f.companyNo.touched && f.companyNo.invalid" class="text-danger">
                      <div *ngIf="f.companyNo.errors?.required">Company Registration No./Pin No. is required.</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="companyName" class="form-label">Company Name:</label>
                    <input type="text" id="companyName" class="form-control" formControlName="companyName" [ngClass]="{ 'is-invalid': f.submitted && f.companyName.errors }">
                    <div *ngIf="f.companyName.touched && f.companyName.invalid" class="text-danger">
                      <div *ngIf="f.companyName.errors?.required">Company Name is Required</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="inputEmail" class="form-label">Company Email:</label>
                    <input type="email"  id="companyEmail" class="form-control" formControlName="companyEmail" [ngClass]="{ 'is-invalid': f.submitted && f.companyEmail.errors }">
                    <div *ngIf="f.companyEmail.touched && f.companyEmail.invalid" class="text-danger">
                      <div *ngIf="f.companyEmail.errors?.required">Company Email is required.</div>
                      Invalid Email Address
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="companyRegistration" class="form-label">Business Description:</label>
                    <input type="text" id="businessDescription" class="form-control" formControlName="businessDescription"[ngClass]="{ 'is-invalid': f.submitted && f.businessDescription.errors }">
                    <div *ngIf="f.businessDescription.touched && f.businessDescription.invalid" class="text-danger">
                      <div *ngIf="f.businessDescription.errors?.required">Business Description is required.</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="physicalLocation" class="form-label">Physical Location:</label>
                    <input type="text" id="physicalLocation" class="form-control" formControlName="physicalLocation" [ngClass]="{ 'is-invalid': f.submitted && f.physicalLocation.errors }">
                    <div *ngIf="f.physicalLocation.touched && f.physicalLocation.invalid" class="text-danger">
                      <div *ngIf="f.physicalLocation.errors?.required">Physical Location is Required</div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="companyPhoneNo" class="form-label">Company Phone Number:</label>
                    <input type="text" id="companyTelephoneNo" class="form-control" formControlName="companyTelephoneNo" [ngClass]="{ 'is-invalid': f.submitted && f.companyTelephoneNo.errors }">
                    <div *ngIf="f.companyTelephoneNo.touched && f.companyTelephoneNo.invalid" class="text-danger">
                      <div *ngIf="f.companyTelephoneNo.errors?.required">Phone Number is required.</div>
                      Check Minimum Input phone number is 10 and Maximum Number is 13
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="inputPassword" class="form-label">Password:</label>
                    <input type="password" id="inputPassword" class="form-control" formControlName="password">
                    <div *ngIf="f.password.touched && f.password.invalid" class="text-danger">
                      <div *ngIf="f.password.errors?.required">Password is required.</div>
                      Input 8 Character Password
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="inputPassword" class="form-label">Confirm Password:</label>
                    <input type="password" class="form-control" formControlName="passwordRepeat">
                    <div *ngIf="f.passwordRepeat.touched && f.passwordRepeat.invalid" class="text-danger">
                      <div *ngIf="f.passwordRepeat?.errors?.required">Password is required.</div>
                      <div *ngIf="f.passwordRepeat?.errors?.confirmedValidator">Password and Confirm Password must be match.</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-grid gap-2">
                      <button type="button" [disabled]="loading" (click)="signup()" [disabled]="!signupForm.valid" class="btn btn-lg px-5 btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Register
                      </button>
                    </div>
                  </div>
                </form>
                  <hr />
                  <div class="col-12 text-center">
                    <a routerLink="/sign-in" class="mb-0">or sign in to your account</a>
                    <!-- <p class="mb-0">or Sign in to your account:</p> -->
                  </div>
                  <hr />
                  <!-- <div class="col-12">
                    <div class="d-grid gap-2">
                      <a routerLink="/sign-in" class="btn btn-facebook btn-lg px-5">Sign in to your account</a>
                    </div>
                  </div> -->
              </div>
            </div>
					</div>
				</div>
				<!--end row-->
			</div>
		</div>
	</div>
	<!--end wrapper-->

