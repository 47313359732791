<!--Start Back To Top Button-->
	<a href="#" (click)="$event.preventDefault()" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
<!--End Back To Top Button-->
<!--footer -->
<footer class="page-footer">
	<p class="mb-0">
    <script type="text/javascript">
      document.write(new Date().getFullYear());
    </script>
  </p>
</footer>
<!-- end footer -->
