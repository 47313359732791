import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {ConfirmedValidator} from '@app/auth/sign-up/confirmed.validator';
import { Standard } from '@app/new-application/standard.model';
import { NewApplicationService } from '@app/new-application/new-application.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  signupForm: FormGroup;
  categoryList:Standard;
  loading = false;
  submitted = false;
  error = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public alertService: ToastrService,
    private newApplicationService: NewApplicationService,
    ) {
      // this.getCategories()
    }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyNo: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email ]],
      businessDescription: ['', Validators.required],
      physicalLocation: ['', Validators.required],
      companyTelephoneNo: ['', [ Validators.required, Validators.maxLength(13), Validators.minLength(10)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordRepeat: ['']
      },
      {
        validator: ConfirmedValidator('password', 'passwordRepeat')      }
    );
  }
  get f() { return this.signupForm.controls; }
  // On Signup link click
  signup() {
    const formValues = this.signupForm.value;
    this.loading = true;
    this.authService.signup(formValues)
    .subscribe({next :(user) =>{
      if(user.responseCode){
        this.alertService.success(user.responseDescription);
        this.router.navigate([this.authService.getInitialPathForRole(user.role)]);

      }else{
        this.error = user.responseDescription;
        this.loading = false;
        // this.alertService.error(user.responseDescription);
      }

  },
  error: error =>{
    this.error = error.error.responseDescription ?? "Try again later";
    this.loading = false;
  }

  });
}

}
