import { OpenAwardedRestrictedTenderModule } from './../open-awarded-restricted-tender/open-awarded-restricted-tender.module';
import { OpenSubmittedRestrictedTenderService } from './open-submitted-restricted-tender.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { OpenSubmittedRestrictedTender } from './OpenSubmittedRestrictedTender';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-open-submitted-restricted-tender',
  templateUrl: './open-submitted-restricted-tender.component.html',
  styleUrls: ['./open-submitted-restricted-tender.component.scss']
})
export class OpenSubmittedRestrictedTenderComponent implements OnInit,OnDestroy{

  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10
};;
  dtTrigger: Subject<any> = new Subject<any>();

  restrictedTEnderList: OpenSubmittedRestrictedTender;
  companyNo: string;
  constructor(private openRestrictedServices: OpenSubmittedRestrictedTenderService,
    public authService: AuthService) {
    this.authService.getCompanyNo$().subscribe(string => this.companyNo = string);
  }

  ngOnInit(): void {
    this.openRestrictedServices.getOpenSubmittedRestricrtedTender(this.companyNo).subscribe(data => {
      this.restrictedTEnderList = data;
      this.dtTrigger.next();
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
