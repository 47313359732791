import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { Subject } from 'rxjs';
import { OpenSubmittedTender } from './open-submitted-tender.model';
import { OpenSubmittedTenderService } from './open-submitted-tender.service';

@Component({
  selector: 'app-open-submitted-tender',
  templateUrl: './open-submitted-tender.component.html',
  styleUrls: ['./open-submitted-tender.component.scss']
})
export class OpenSubmittedTenderComponent implements OnInit, OnDestroy {
  companyNo: string;

  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10
};

dtTrigger: Subject<any> = new Subject<any>();


  submittedList: OpenSubmittedTender;
  constructor(private activeSubmittedTenderService: OpenSubmittedTenderService,  public authService: AuthService,) {
    this.authService.getCompanyNo$().subscribe(string => this.companyNo = string);
   }

  ngOnInit(): void {
    this.activeSubmittedTenderService.getSubmittedTender(this.companyNo).subscribe(data => {
      this.submittedList = data;
      this.dtTrigger.next();
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
