import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-awarded-expression-of-intrest',
  templateUrl: './awarded-expression-of-intrest.component.html',
  styleUrls: ['./awarded-expression-of-intrest.component.scss']
})
export class AwardedExpressionOfIntrestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
