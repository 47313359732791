interface Config {
  [key: string]: string;
  auth: 'session' | 'token';
}

// Session auth needs to use the same origin anyway
export const config: Config = {
  // apiUrl:'http://api1.tenders.pu.ac.ke/api',
  apiUrl:'http://eprocurementapi.tenders.pu.ac.ke/api',
  // apiUrl:' http://microservices.sysre.co.ke:4046/api',
  auth: 'token'
};
