import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EOI } from '@app/active-expression-of-intrest/eoi.model';
import { config } from '@app/shared/config';
import { Observable } from 'rxjs';


const api_url = config.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SubmittedExpressionOfIntrestService {

  constructor(private httpClient: HttpClient) { }

  getEoiByCompanyNo(companyNo): Observable<EOI>{
    return this.httpClient.get<EOI>(api_url+ '/ExpressionOfInterest/GetSubmittedEOIs/'+ companyNo);

  }
}
