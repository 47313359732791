  <!--breadcrumb-->
  <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3 p-4" style="background-color:#04a7f3;">
    <div class="breadcrumb-title pe-3n text-dark">Submitted Restricted Tenders</div>
    <div class="ps-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0 p-0">
          <li class="breadcrumb-item"><a href="#" (click)="$event.preventDefault()"><i class="bx bx-home-alt"></i></a>
          </li>
          <li class="breadcrumb-item active text-dark" aria-current="page">Submitted Restricted Tenders</li>
        </ol>
      </nav>
    </div>
  </div>
  <!--end breadcrumb-->
  <div class="col-sm-12">
  <div class="p-4 table-responsive-sm border rounded">
    <table class="table table-hover mb-0 " datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead class="table-light">
        <tr>
          <th scope="col">Tender No</th>
          <th scope="col">Proc Method</th>
          <th scope="col">Submisssion Method</th>
          <th scope="col">Tender Fee</th>
          <th scope="col">Publish Date</th>
          <th scope="col">Closing date</th>
          <th scope="col">Opening venue</th>
          <th scope="col">PE</th>
          <th scope="col">Addendum</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let submittedTender of restrictedTEnderList">
          <td>{{submittedTender.no}}</td>
          <td>{{submittedTender.procurementMethod}}</td>
          <td>{{submittedTender.category}}</td>
          <td>{{submittedTender.tenderFee}}</td>
          <td>{{submittedTender.publishedDate}}</td>
          <td>{{submittedTender.closeDate}}</td>
          <td>{{submittedTender.openingVenue}}</td>
          <td>{{submittedTender.procumentExecutive}}</td>
          <td>{{submittedTender.addendum}}</td>
          <td>{{submittedTender.tenderStatus}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
