import { Component , OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit{
  public companyEmail$: Observable<string>;
  public companyName$: Observable<string>;

    constructor(
      public sidebarservice: SidebarService,
      public authService: AuthService,
      private router: Router
      ) {
        this.companyEmail$ = this.authService.getUsername$();
        this.companyName$ = this.authService.getFirstName$();
       }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }
    logout() {
      this.authService.logout()
        .subscribe(() => {
          this.router.navigate([this.authService.LOGIN_PATH]);
        });
    }

    ngOnInit() {

        /* Search Bar */
        $(document).ready(function () {
            $(".mobile-search-icon").on("click", function () {
                $(".search-bar").addClass("full-search-bar")
            }),
            $(".search-close").on("click", function () {
                $(".search-bar").removeClass("full-search-bar")
            })
        });

    }
}
