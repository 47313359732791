import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { Subject } from 'rxjs';
import { SubmittedRequestForQuatationService } from './submitted-request-for-quatation.service';
import { SubmittedRfq } from './submitted-rfq.model';

@Component({
  selector: 'app-submitted-request-for-quatation',
  templateUrl: './submitted-request-for-quatation.component.html',
  styleUrls: ['./submitted-request-for-quatation.component.scss']
})
export class SubmittedRequestForQuatationComponent implements OnInit {

  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10
  };
 dtTrigger: Subject<any> = new Subject<any>();

  submittedRfqsList: SubmittedRfq;
  companyNo: string;

  constructor(private submittedRfqs: SubmittedRequestForQuatationService,  public authService: AuthService,) {
    this.authService.getCompanyNo$().subscribe(string => this.companyNo = string);
   }

  ngOnInit(): void {
    this.submittedRfqs.getSubmittedRfqs(this.companyNo).subscribe(data=>{
      this.submittedRfqsList = data;
      this.dtTrigger.next();
    })
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
