<!--breadcrumb-->
<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3 p-4" style="background-color:#04a7f3;">
  <div class="breadcrumb-title pe-3n text-dark">Awarded Tenders</div>
  <div class="ps-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 p-0">
        <li class="breadcrumb-item"><a href="#" (click)="$event.preventDefault()"><i class="bx bx-home-alt"></i></a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">Awarded Tenders</li>
      </ol>
    </nav>
  </div>
</div>
<!--end breadcrumb-->
<div class="card">
  <div class="card-body">
    <div class="table-responsive">
    <table class="table table-hover mb-0"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead class="table-light">
        <tr>
          <th scope="col">Tender No</th>
          <th scope="col">Proc Method</th>
          <th scope="col">Submission Method</th>
          <th scope="col">Tender Fee</th>
          <th scope="col">Publish Date</th>
          <th scope="col">Closing date</th>
          <th scope="col">Opening venue</th>
          <th scope="col">Addendum</th>
          <th scope="col">Status</th>
          <th scope="col">View</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let openTender of openTenderList">
          <td>{{openTender.no}}</td>
          <td>{{openTender.procurementMethod}}</td>
          <td>{{openTender.category}}</td>
          <td>{{openTender.tenderFee}}</td>
          <td>{{openTender.publishedDate}}</td>
          <td>{{openTender.closeDate}}</td>
          <td>{{openTender.openingVenue}}</td>
          <td>{{openTender.addendum}}</td>
          <td>{{openTender.tenderStatus}}</td>
          <td>
            <a class="btn btn-sm btn-success" [href]="openTender?.documementLink" target="_blank">Attachment</a>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>
