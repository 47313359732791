<html>
<!--breadcrumb-->
<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3 p-4" style="background-color:#04a7f3;">
	<div class="breadcrumb-title pe-3n text-dark">Awarded Expressions of Interest(EOI)</div>
	<div class="ps-3">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb mb-0 p-0">
				<li class="breadcrumb-item"><a href="#" (click)="$event.preventDefault()"><i class="bx bx-home-alt"></i></a>
				</li>
				<li class="breadcrumb-item active text-dark" aria-current="page">Awarded Expressions of Interest(EOI)</li>
			</ol>
		</nav>
	</div>
</div>
<!--end breadcrumb-->
<!-- Page-body start -->
 <div class="body">
  <div class="card">
    <div class="card-header">
      <h5>Awarded Expressions of Interest(EOI)</h5>
      <span>Your awarded Expressions of Interest(EOI)</span>
      <div class="card-header-right">
        <ul class="list-unstyled card-option">
          <li><i class="fa fa fa-wrench open-card-option"></i></li>
          <li><i class="fa fa-window-maximize full-card"></i></li>
          <li><i class="fa fa-minus minimize-card"></i></li>
          <li><i class="fa fa-refresh reload-card"></i></li>
          <li><i class="fa fa-trash close-card"></i></li>
        </ul>
      </div>
    </div>
    <div class="card-block table-border-style m-3">
      <div class="alert alert-danger">No Awarded Expressions of Interest(EOI)</div>
    </div>
  </div>
</div>
</html>

