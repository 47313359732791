import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/shared/config';
import { Observable } from 'rxjs';
import { OpenSubmittedTender } from './open-submitted-tender.model';


const api_url = config.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class OpenSubmittedTenderService {

  constructor(private httpClient: HttpClient) { }


  getSubmittedTender(companyNo): Observable<OpenSubmittedTender>{
    return this.httpClient.get<OpenSubmittedTender>(api_url +'/Tenders/GetSubmittedTenders/'+ companyNo)

  }
}
