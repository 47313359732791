import { EOI } from './../active-expression-of-intrest/eoi.model';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { SubmittedExpressionOfIntrestService } from './submitted-expression-of-intrest.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-submitted-expression-of-intrest',
  templateUrl: './submitted-expression-of-intrest.component.html',
  styleUrls: ['./submitted-expression-of-intrest.component.scss']
})
export class SubmittedExpressionOfIntrestComponent implements OnInit {

  submittedEoiList: EOI;
  companyNo: string;

  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10
  };
 dtTrigger: Subject<any> = new Subject<any>();

  constructor(public authService: AuthService, private eoiServices: SubmittedExpressionOfIntrestService) { 
    this.authService.getCompanyNo$().subscribe(string => this.companyNo = string);
  }

  ngOnInit(): void {
    this.getAllSubmittedEOIByCompanyNo()
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllSubmittedEOIByCompanyNo(){
    this.eoiServices.getEoiByCompanyNo(this.companyNo).subscribe(data=>{
      this.submittedEoiList = data;
      this.dtTrigger.next();
    })
  }

}
