import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { PasswordService } from '../services/password.service.spec';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isRequestSent: boolean;
  public recoveryForm: FormGroup;  loading = false;
  submitted = false;

  constructor(private router: Router, private route: ActivatedRoute,private formBuilder: FormBuilder,
    private passwordService: PasswordService) { }

	// On SignIn link click
	onSignIn() {
	  this.router.navigate(['sign-in'], { relativeTo: this.route.parent });
	}


  ngOnInit(): void {
    this.isRequestSent = false;
    this.recoveryForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required]]
    });
  }
  get f() {
     return this.recoveryForm?.controls;
     }
  recover() {
    this.loading = true;
    this.passwordService.requestRecovery(this.f.emailAddress.value)
      .subscribe(() => this.isRequestSent = true);
      this.loading = false;
  }

}
