import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs/operators';
import { LoginRequest } from '../models/loginRequest';
//import { AlertService } from '@app/shared/alert/alert.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signinForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  closeResult = '';


    constructor(
      private modalService: NgbModal,
      public fb: FormBuilder,
      private router: Router,
      private route: ActivatedRoute,
      private authService: AuthService,
      public alertService: ToastrService
      ) {}


      open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }

      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
    // On Forgotpassword link click
  // tslint:disable-next-line:typedef
    onForgotpassword() {
      this.router.navigate(['forgot-password'], { relativeTo: this.route.parent });
    }

    // On Signup link click
  // tslint:disable-next-line:typedef
    onSignup() {
      this.router.navigate(['sign-up'], { relativeTo: this.route.parent });
    }


  ngOnInit(): void {
      this.signinForm = this.fb.group({
        companyEmail: ['', Validators.required],
        password: ['', Validators.required]
      });

  }

  // convenience getter for field values
  // tslint:disable-next-line:typedef
  get f() { return this.signinForm?.controls; }

  // tslint:disable-next-line:typedef
  login() {
    const loginRequest: LoginRequest = {
      companyEmail: this.f.companyEmail.value,
      password: this.f.password.value
    };
    this.loading = true;
    this.authService.login(loginRequest)
    .subscribe({next :(user) =>{
                if(user.responseCode){
                  this.alertService.success(user.responseDescription);
                  this.router.navigate([this.authService.getInitialPathForRole(user.role)])

                }else{
                  this.error = user.responseDescription;
                  this.loading = false;
                  // this.alertService.error(user.responseDescription);
                }

            },
            error: error =>{
              this.error = error.error.responseDescription ?? "Try again later";
              this.loading = false;
            }

            });
  }

}
