import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenAwardedTender } from '@app/open-awarded-tender/open-awarded-tender.model';
import { config } from '@app/shared/config';
import { Observable } from 'rxjs';

const api_url = config.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class OpenAwardedTenderService {

  constructor(private httpClient: HttpClient) { }

  getOpenAwardedTender(companyNo): Observable<OpenAwardedTender>{
    return this.httpClient.get<OpenAwardedTender>(api_url +'/Tenders/GetAwardedTenders/'+companyNo)

  }
}
