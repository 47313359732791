import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ACTIVE_USER_ROUTES: RouteInfo[] = [

    {
        path: '/dashboard/member-dashboard', title: 'HOME', icon: 'lni lni-dashboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '', title: 'MEMBERSHIP', icon: 'bx bx-user-circle', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/profile', title: 'My Profile', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/member-list', title: 'All Members', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/products', title: 'Products/Services', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/map-view', title: 'Members MapView', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/issue-handling', title: 'HelpDesk', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/member-withdrawal', title: 'Withdrawals', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'KAM CONSULTING', icon: 'lni lni-consulting', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: '/events', title: 'Events', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/events/trainings', title: 'Trainings', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/events/seminars', title: 'Seminars', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/events/workshops', title: 'Workshops', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/technical-assesments', title: 'Technical Assesments', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            ]
    },
    {
        path: '', title: 'PRAU SERVICES', icon: 'lni lni-service', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: 'javascript:;', title: 'PRAU-TAXATION', icon: 'bx bx-right-arrow-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
                submenu: [

                    { path: '/budget-proposals', title: 'Budget Proposals', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/drs-applications', title: 'DRS Application', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/fact-based-policy-research', title: 'Fact Based Policy Research', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ]
            },
            { path: 'javascript:;', title: 'PRAU-TRADE', icon: 'bx bx-right-arrow-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
                submenu: [

                    { path: '/agoa-visas', title: 'Agoa Visas', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/declaration-fees', title: 'Import Declaration Fee/Railway Development Levy', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/illicit-trade', title: 'Illicit Trade', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/industrial-spares', title: 'Industrial Spares', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/lmg', title: 'LMG', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/non-tariff-barriers', title: 'Non Tariff Barriers', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/trade-regimes', title: 'Trade Regimes', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/work-permits', title: 'Work Permits', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/dependant-passes', title: 'Dependant Passes', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/special-passes', title: 'Special Passes', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ]
            },
            { path: 'javascript:;', title: 'PRAU-LEGAL', icon: 'bx bx-right-arrow-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
                submenu: [

                    { path: '/case', title: 'Cases', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/bills', title: 'Bills', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/regulations-audit-tracker', title: 'Regulatory Audit Tracker', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/gazette-notice', title: 'Gazette Notice', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ]
            }
        ]
    },


    {
        path: '', title: 'Application', icon: 'bx bx-category', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/application/email-app', title: 'Email', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/chat-box', title: 'Chat Box', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/file-manager', title: 'File Manager', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/contatcs', title: 'Contatcs', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/invoice', title: 'Invoice', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/calendar', title: 'Calendar', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    { path: '/widgets', title: 'Widgets', icon: 'bx bx-cookie', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    {
        path: '', title: 'eCommerce', icon: 'bx bx-cart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/ecommerce/products', title: 'Products', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ecommerce/products-details', title: 'Products Details', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ecommerce/add-new-products', title: 'Add New Products', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ecommerce/orders', title: 'Orders', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Components', icon: 'bx bx-bookmark-heart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/components/alerts', title: 'RegulationsAuditTracker', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/badge', title: 'Badge', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/buttons', title: 'Buttons', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/cards', title: 'Cards', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/carousel', title: 'Carousel', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/acordians', title: 'Accordion', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/list-groups', title: 'List Groups', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/media-objects', title: 'Media Objects', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/modals', title: 'Modals', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/navs', title: 'Navs', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/navbar', title: 'Navbar', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/pagination', title: 'Pagination', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/progress-bar', title: 'Progress Bars', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/spinners', title: 'Spinners', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/avtars-chips', title: 'Avatrs & Chips', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Content', icon: 'bx bx-repeat', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/content/grid-system', title: 'Grid System', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/content/typography', title: 'Typography', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/content/text-utilities', title: 'Text Utilities', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Icons', icon: 'bx bx-donate-blood', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/icons/line-icons', title: 'Line Icons', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/icons/boxicons', title: 'Boxicons', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Form', icon: 'bx bx-message-square-edit', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/form/form-elements', title: 'Form Elements', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/input-groups', title: 'Input Groups', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/form-layouts', title: 'Forms Layouts', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/form-validation', title: 'Form Validation', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/form-wizard', title: 'Form Wizard', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/text-editor', title: 'Text Editor', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/file-upload', title: 'File Upload', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/select2', title: 'Select2', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
           ]
    },
    {
        path: '/table/table', title: 'Table', icon: 'bx bx-grid-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Authentication', icon: 'bx bx-lock', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [

            { path: '/auth/sign-in', title: 'Sign In', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/sign-up', title: 'Sign Up', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/signin-with-header-footer', title: 'SignIn With Header Footer', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/signup-with-header-footer', title: 'SignUp With Header Footer', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/forgot-password', title: 'Forgot Password', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/reset-password', title: 'Reset Password', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/lock-screen', title: 'Lock Screen', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },

        ]
    },
    { path: '/user-profile', title: 'User Profile', icon: 'bx bx-user-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    { path: '/timeline', title: 'Timeline', icon: 'bx bx-video-recording', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    {
        path: '', title: 'Errors', icon: 'bx bx-error', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/kam-angular/demo/vertical/error/error-404', title: '404 Error', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/kam-angular/demo/vertical/error/error-500', title: '500 Error', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/kam-angular/demo/vertical/error/coming-soon', title: 'Coming Soon', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
           ]
    },
    { path: '/faq', title: 'FAQ', icon: 'bx bx-help-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/pricing', title: 'Pricing', icon: 'bx bx-diamond', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/earnings', title: 'Earnings', icon: 'bx bx-dollar-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/downloads', title: 'Downloads', icon: 'bx bx-download', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '', title: 'Charts', icon: 'bx bx-line-chart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/charts/apex-chart', title: 'Apex Charts', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/charts/chartjs', title: 'ChartJs', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/charts/highcharts', title: 'Highcharts', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Maps', icon: 'bx bx-map-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/maps/google-maps', title: 'Google Maps', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/maps/fullscreen', title: 'Fullscreen Map', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: 'javascript:;', title: 'Menu Levels', icon: 'bx bx-menu', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: 'javascript:;', title: 'Level 1', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: 'javascript:;', title: 'Level 1', icon: 'bx bx-right-arrow-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
                        submenu: [
                            { path: 'javascript:;', title: 'level 2', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                            { path: 'javascript:;', title: 'level 2', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

                        ] },
            ]
    },
    { path: 'https://codervent.com/kam-angular/demo/vertical/docs/', title: 'Documentation', icon: 'bx bx-folder', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    { path: 'https://themeforest.net/user/codewrrap/portfolio', title: 'Support', icon: 'bx bx-support', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] }


];
export const OPEN_USER_ROUTES: RouteInfo[] = [

    // {
    // path: '/dashboard', title: 'Dashboard', icon: 'bx bx-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [
    // ]
    // },


    // { path: '/my-profile', title: 'User Profile', icon: 'bx bx-user-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    // { path: '/newApplication', title: 'Business Profile', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    {
    path: '', title: 'Prequalification', icon: 'lni lni-layout', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/prequalification', title: 'Active Applications ', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/approvedApplication', title: 'Approved Applications', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/rejectedApplication', title: 'Rejected Applications', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/newApplication', title: 'Business Profile', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/application', title: 'Applications', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/awardedRequestForQuotation', title: 'Awarded Request For Quotation', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
  //       {
  //     path: '', title: 'Tenders', icon: 'lni lni-wallet', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
  //         submenu: [
  //             { path: '', title: 'Open Tenders', icon: 'bx bx-plus', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
  //             submenu: [
  //               {
  //                 path: '/openTender', title: 'Active Tenders', icon: 'lni lni-layers', class: '', badge: '', badgeClass: '', isExternalLink: false,
  //                     submenu: []
  //             },
  //             {
  //               path: '/openSubmittedTender', title: 'Submitted Tenders', icon: 'lni lni-checkmark-circle', class: '', badge: '', badgeClass: '', isExternalLink: false,
  //                   submenu: []
  //             },
  //             {
  //               path: '/openAwardedTender', title: 'Awarded Tenders', icon: 'lni lni-checkmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
  //                   submenu: []
  //             },
  //             ] },
  //             { path: '', title: 'Restrict Tenders', icon: 'bx bx-plus', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
  //             submenu: [
  //               {
  //                 path: '/restrictedTender', title: 'Active Restrict Tenders', icon: 'lni lni-layers', class: '', badge: '', badgeClass: '', isExternalLink: false,
  //                     submenu: []
  //             },
  //             {
  //               path: '/openSubmittedRestrictedTender', title: 'Submitted Restrict Tenders', icon: 'lni lni-checkmark-circle', class: '', badge: '', badgeClass: '', isExternalLink: false,
  //                   submenu: []
  //             },
  //             {
  //               path: '/openSubmittedAwardedTender', title: 'Awarded Restrict Tenders', icon: 'lni lni-checkmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
  //                   submenu: []
  //             },
  //             ] },
  //             { path: '/closedTender', title: 'Closed Tender', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         ]
  // },
  {
    path: '', title: 'Tenders', icon: 'lni lni-write', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/openTender', title: 'Active Tenders', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/openSubmittedTender', title: 'Submitted Tenders', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/openAwardedTender', title: 'Awarded Tenders', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/closedTender', title: 'Closed Tender', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
  },
  {
    path: '', title: 'Request for Quotation', icon: 'lni lni-write', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/activeRequestForQuotation', title: 'Active RFQs', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/submittedRequestForQuotation', title: 'Submitted RFQs', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/awardedRequestForQuotation', title: 'Awarded Request For Quotation', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
  },


    {
      path: '', title: 'Request for Proposal', icon: 'lni lni-telegram-original', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
          submenu: [
              { path: '/activeReqestForProposal', title: 'Active RFPs', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              { path: '/submittedReqestForProposal', title: 'Closed RFPs', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              // { path: '/awardedrequestForProposal', title: 'Awarded Request for Proposal', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          ]
    },


    {
      path: '', title: 'Expression of Interest', icon: 'lni lni-hand', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
          submenu: [
              { path: 'activeExpressionOfIntrest', title: 'Active EOIs', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              { path: 'submittedExpressionOfIntrest', title: 'Closed EOIs', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
              // { path: 'awardedExpressionOfIntrest', title: 'Awarded Expresion of Intrest', icon: 'bx bx-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          ]
    },
];
