import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { Subject } from 'rxjs';
import { OpenAwardedTender } from './open-awarded-tender.model';
import { OpenAwardedTenderService } from './open-awarded-tender.service';

@Component({
  selector: 'app-open-awarded-tender',
  templateUrl: './open-awarded-tender.component.html',
  styleUrls: ['./open-awarded-tender.component.scss']
})
export class OpenAwardedTenderComponent implements OnInit,  OnDestroy {

  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10
};
dtTrigger: Subject<any> = new Subject<any>();

  openTenderList: OpenAwardedTender;
  companyNo: string;
  constructor(private openAwardedTenderService: OpenAwardedTenderService, public authService: AuthService) {
    this.authService.getCompanyNo$().subscribe(string => this.companyNo = string);
   }

  ngOnInit(): void {
   this.openAwardedTenderService.getOpenAwardedTender(this.companyNo).subscribe(data => {
    this.openTenderList = data;
    this.dtTrigger.next();
  });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
