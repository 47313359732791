import { DataTablesModule } from 'angular-datatables';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "./shared/shared.module";
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';

import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

import * as $ from 'jquery';
import { ToastrModule } from 'ngx-toastr';
import { RestrictedTendersComponent } from './restricted-tenders/restricted-tenders.component';
import { SubmittedExpressionOfIntrestComponent } from './submitted-expression-of-intrest/submitted-expression-of-intrest.component';
import { AwardedExpressionOfIntrestComponent } from './awarded-expression-of-intrest/awarded-expression-of-intrest.component';
import { SubmittedRequestForProposalComponent } from './submitted-request-for-proposal/submitted-request-for-proposal.component';
import { AwardedRequestForProposalComponent } from './awarded-request-for-proposal/awarded-request-for-proposal.component';
import { SubmittedRequestForQuatationComponent } from './submitted-request-for-quatation/submitted-request-for-quatation.component';
import { AwardedRequestForQuatationComponent } from './awarded-request-for-quatation/awarded-request-for-quatation.component';
import { OpenSubmittedTenderComponent } from './open-submitted-tender/open-submitted-tender.component';
import { OpenAwardedTenderComponent } from './open-awarded-tender/open-awarded-tender.component';
import { OpenSubmittedRestrictedTenderComponent } from './open-submitted-restricted-tender/open-submitted-restricted-tender.component';
import { OpenAwardedRestrictedTenderComponent } from './open-awarded-restricted-tender/open-awarded-restricted-tender.component';


@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    RestrictedTendersComponent,
    SubmittedExpressionOfIntrestComponent,
    AwardedExpressionOfIntrestComponent,
    SubmittedRequestForProposalComponent,
    AwardedRequestForProposalComponent,
    SubmittedRequestForQuatationComponent,
    AwardedRequestForQuatationComponent,
    OpenSubmittedTenderComponent,
    OpenAwardedTenderComponent,
    OpenSubmittedRestrictedTenderComponent,
    OpenAwardedRestrictedTenderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    NgbModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyDKXKdHQdtqgPVl2HI2RnUa_1bjCxRCQo4'}),
    PerfectScrollbarModule,
    DataTablesModule



  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
