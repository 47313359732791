import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '@app/shared/config';
import { Observable } from 'rxjs';
import { SubmittedRfq } from './submitted-rfq.model';

const api_url = config.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SubmittedRequestForQuatationService {

  constructor(private httpClient: HttpClient) { }

  getSubmittedRfqs(companyNo): Observable<SubmittedRfq>{
    return this.httpClient.get<SubmittedRfq>(api_url+ '/RFQs/GetSubmittedRFQs/'+ companyNo);

  }

}
