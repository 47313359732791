import { AuthService } from './../auth/services/auth.service';
import { RestrictedTenders } from './restricted-tenders.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RestrictedTendersService } from './restricted-tenders.service';
import { Subject } from 'rxjs/internal/Subject';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-restricted-tenders',
  templateUrl: './restricted-tenders.component.html',
  styleUrls: ['./restricted-tenders.component.scss']
})
export class RestrictedTendersComponent implements OnInit, OnDestroy {

  restrictedTenderLists: RestrictedTenders;
  restrictedTenderRowList: RestrictedTenders;
  closeModal: string;
  tenderNo: string;
  companyNo: string;



  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 10
};
  dtTrigger: Subject<any> = new Subject<any>();


  constructor(public restrictedTenders: RestrictedTendersService,
    private modalService: NgbModal,
    private authService: AuthService
    ) {
      this.authService.getCompanyNo$().subscribe(string => this.companyNo = string)
     }

  triggerModal(content, tenderNo) {
    this.tenderNo = tenderNo;
    this.getRestrictedTenderByTenderNo$();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.restrictedTenders.getOpenRestictedTender(this.companyNo).subscribe(data=>{
      this.restrictedTenderLists = data;
      this.dtTrigger.next();
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  getRestrictedTenderByTenderNo$(){
    this.restrictedTenders.getRestrictedTenderByTenderNo(this.tenderNo).subscribe(data=>{
    this.restrictedTenderRowList = data;
    })
  }


}
